import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Badge,
  Button,
  CardTitle,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// Core Components

function HomePageTable(props) {
    const [tableHeader, setTableHeader] = React.useState(['Company Name', 'Rating', 'Contact']);

  return (
    <>
      <div className="tables-2 mb-5 mt-2 search-table">
        <Container fluid>
          <Row>
            <Col className="px-0">
              <div className="pt-2 table-container">
                <Table className="table-pricing" responsive>
                  <thead className="text-primary">
                    <tr className="border-bottom">
                      {tableHeader.map((item, index) => (
                        <td className="text-dark" key={index}>
                          <b style={{ fontSize: 'large' }}>{item}</b>
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.tableData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Link to={`/company-profile/${item.id}`} style={{ fontWeight: 200, color: 'black' }}>
                            <b className="text-black fw-normal" style={{ fontSize: 'larger' }}>
                              {item.companyName}
                            </b>
                          </Link>
                        </td>
                        <td style={{ fontSize: 'larger' }}>{item.rating}</td>
                        <td style={{ fontSize: 'larger' }}>{item.contact.join(', ')}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HomePageTable;