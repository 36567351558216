import { useState, useCallback, useRef } from 'react';
import { compSearchApi } from '../services/compSearchApi';
import { debounce } from '../utils/helper';

export function useSearch(delay = 300) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  // Use useRef to store the debounced function
  const debouncedSearchRef = useRef();

  // Initialize the debounced function
  debouncedSearchRef.current = debounce(async (searchQuery) => {
    if (searchQuery.trim() === '') {
      setResults([]);
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await compSearchApi.searchCompData(searchQuery);
      console.log("Resp: ", response)
      // return
      // Process the nested data
      const processedResults = response.data.map(company => ({
        id: company._id,
        companyName: company.CompanyName,
        companyLogo: company.CompanyLogo,
        contact: company.ContactNumbers,
        rating: company.OverallScore,
        category: company.Category,
        companyType: company.CompanyType,
        description: company.Description,
        generatedDescription: company.GeneratedDescription,
        numberOfReviews: company.NumberOfReviews,
        skills: company.Skills,
        zipCode: company.ZipCode
      }));

      setResults(processedResults);
    } catch (err) {
      console.error('Search error:', err);
      setError('An error occurred while searching. Please try again.');
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  }, delay);

  const handleSearch = useCallback((searchQuery) => {
    setQuery(searchQuery);
    debouncedSearchRef.current(searchQuery);
  }, []);

  const getDataById = useCallback(async (id) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await compSearchApi.getCompanyDetails(id);
      console.log("Response: ", response);
      const companyData = response.data; // The company data is now directly in response.data
      
      // Process the data for a single company
      const processedData = {
        id: companyData._id, // Changed from companyData.id to companyData._id
        companyName: companyData.CompanyName,
        companyLogo: companyData.CompanyLogo,
        contact: companyData.ContactNumbers,
        rating: companyData.OverallScore,
        skills: companyData.Skills,
        description: companyData.Description,
        generatedDescription: companyData.GeneratedDescription,
        customerReviews: companyData.CustomerReviews,
        category: companyData.Category,
        zipCode: companyData.ZipCode,
        companyType: companyData.CompanyType,
        numberOfReviews: companyData.NumberOfReviews
        // Add any other fields you need
      };
      
      setSelectedCompany(processedData);
      return processedData;
    } catch (err) {
      console.error('Error fetching company data:', err);
      setError('An error occurred while fetching company data. Please try again.');
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { query, results, isLoading, error, handleSearch, getDataById, selectedCompany };
}