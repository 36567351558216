import React from "react";

// reactstrap components
import { Button, Card, Row, Col } from "reactstrap";
import checkatrade from "../../assets/img/trade-checker/checkatrade-logo.webp";
import google from "../../assets/img/trade-checker/google-logo-transparent.png";
import yell from "../../assets/img/trade-checker/yell-logo.png";

// Core Components

function ProfileCard7({ selectedCompany }) {
  const stars = [1, 2, 3, 4, 5];

  if (!selectedCompany) {
    return <div>No company data available.</div>;
  }

  return (
    <>
      <Card className="card-profile shadow mt--300">
        <div className="px-4">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="..."
                    className=""
                    src={selectedCompany.companyLogo || "https://whimsysoul.com/wp-content/uploads/2020/02/Whimsy-Soul-Dunder-Mifflin-The-Office-3.png"}
                  ></img>
                </a>
              </div>
            </Col>
            <Col
              className="order-lg-3 text-lg-right align-self-lg-center"
              lg="4"
            >
            </Col>
            <Col className="order-lg-1" lg="4">
            </Col>
          </Row>
          <div className="text-center" style={{ marginTop: '100px' }}>
            <h3>
              {selectedCompany.companyName}
            </h3>
            <div className="h6 font-weight-300">
              <i className="ni location_pin mr-2"></i>
              {selectedCompany.zipCode}
            </div>
            <div className="h6 mt-4">
              <i className="ni business_briefcase-24 mr-2"></i>
              {selectedCompany.category}
            </div>
          </div>
          <div className="mt-3 py-5 text-center border-bottom">
            <Row className="justify-content-center">
              <Col lg="9">
                <p>
                  {selectedCompany.description || "No description available."}
                </p>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-between mx-auto py-5 align-items-center" style={{maxWidth:'70%'}}>
            <div>
              <img src={checkatrade} style={{ height: '90px' }} alt="Checkatrade" />
              <div className="d-flex align-items-center mt-2">
                {stars.map((star, index) => (
                  <svg key={index} width="15" height="15" fill="#0F70E6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
                ))}
              </div>
              <span>({selectedCompany.numberOfReviews || 0} customer reviews)</span>
            </div>
            <div>
              <img src={google} style={{ height: '90px' }} alt="Google" />
              <div className="d-flex align-items-center mt-2">
                {stars.map((star, index) => (
                  <svg key={index} width="15" height="15" fill="#0F70E6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
                ))}
              </div>
              <span>(Google reviews not available)</span>
            </div>
            <div>
              <img src={yell} style={{ height: '90px' }} alt="Yell" />
              <div className="d-flex align-items-center mt-2">
                {stars.map((star, index) => (
                  <svg key={index} width="15" height="15" fill="#0F70E6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
                ))}
              </div>
              <span>(Yell reviews not available)</span>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ProfileCard7;