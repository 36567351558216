import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container } from "reactstrap";
import DemoNavbar from "components/navbars/DemoNavbar.js";
import DemoFooter from "components/footers/DemoFooter.js";
import ProfileCard7 from "components/cards/ProfileCard7.js";
import { useParams } from 'react-router-dom';
import { useSearch } from '../hooks/useSearch';

function ProfilePage() {
  const { id } = useParams();
  const { getDataById, selectedCompany, isLoading, error } = useSearch();
  const [isPageSetup, setIsPageSetup] = useState(false);

  const setupPage = useCallback(async () => {
    // Fetch data
    await getDataById(id);

    // DOM manipulation
    document.body.classList.add("profile-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    setIsPageSetup(true);
  }, [getDataById, id]);

  useEffect(() => {
    setupPage();

    // Cleanup function
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, [setupPage]);

  if (!isPageSetup) {
    return null; // or a loading spinner
  }

  return (
    <>
      <DemoNavbar type="transparent" />
      <div className="wrapper">
        <section className="section-profile-cover section-shaped my-0">
          <img
            alt="..."
            className="bg-image"
            src={require("assets/img/pages/mohamed.jpg")}
            style={{ width: "100%" }}
          ></img>
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-secondary"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="section bg-secondary">
          <Container style={{ maxWidth: '1600px' }}>
            {isLoading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>Error: {error}</div>
            ) : selectedCompany ? (
              <ProfileCard7 selectedCompany={selectedCompany} />
            ) : (
              <div>No company data available.</div>
            )}
          </Container>
        </section>
        <DemoFooter />
      </div>
    </>
  );
}

export default ProfilePage;