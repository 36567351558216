import React from "react";
import { useSearch } from "../../hooks/useSearch"; // Adjust the path as needed

// Keep your existing imports
import {
  Badge,
  Button,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import HomePageTable from "components/tables/HomePageTable";
import logo from "../../assets/img/trade-checker/logo.png";

function HeaderIndex() {
  const [searchFocus, setSearchFocus] = React.useState("");
  const { query, results, isLoading, error, handleSearch } = useSearch();

  return (
    <>
      <div className="section section-hero section-shaped">
        <div className="page-header">
          <Container className="shape-container d-flex align-items-center py-lg">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center">
                  <p className="lead">
                    <img src={logo} style={{ height: "80px" }} alt="Logo" />
                    <br />
                    Find reliable trades people in South London. Which trade do you need?
                  </p>
                  <div>
                    <FormGroup className={searchFocus}>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-zoom-split-in"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search"
                          type="text"
                          value={query}
                          onFocus={() => setSearchFocus("focused")}
                          onBlur={() => setSearchFocus("")}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </InputGroup>
                      {isLoading && <p>Loading...</p>}
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                      <HomePageTable tableData={results} />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default HeaderIndex;