import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import Index from "views/Index.js";
import Home from "views/Home.js";
import ProfilePage from "views/CompanyProfile.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/index" element={<Index />} />
      <Route path="/Company-profile/:id" element={<ProfilePage />} />
      <Route path="/" element={<Home />} />
      {/* <Route path="/" element={<Navigate to="/presentation" replace />} /> */}
    </Routes>
  </BrowserRouter>
);